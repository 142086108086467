<!-- Component for rendering the tenant's logo
Will render SentryHealth logo by default
-->
<template>
  <v-img
    contain
    :max-width="maxWidth"
    :max-height="height"
    :src="src"
    :alt="$t(`logo.${prefix}`)"
    v-bind="$props"
    @error="useDefault"
  />
</template>
<script>
/* eslint-disable global-require */
import { VUE_APP_PUBLIC_ASSETS_BUCKET } from '../constants/env';

const defaultImages = {
  light: require('../images/logo-full.png'),
  dark: require('../images/logo-dark.png'),
};

export default {
  name: 'LogoImage',
  data() {
    return {
      src: null,
    };
  },
  methods: {
    setSrc() {
      if (!this.prefix) {
        if (this.mode) {
          this.src = defaultImages[this.mode];
        } else {
          this.src = defaultImages.light;
        }
      } else {
        try {
          this.src = `${VUE_APP_PUBLIC_ASSETS_BUCKET}/logo/${this.prefix}-${this.mode}${
            this.lang ? '-' : ''
          }${this.lang ?? ''}.png?n=${Math.random()}`;
        } catch {
          this.src = defaultImages.light;
        }
      }
    },
    useDefault() {
      this.src = defaultImages[this.mode];
    },
  },

  props: {
    height: {
      type: String,
      default() {
        return '125px';
      },
    },
    prefix: String,
    maxWidth: {
      type: String,
      default() {
        return '250px';
      },
    },
    lang: String,
    mode: {
      type: String,
      default: 'light',
    },
  },
  mounted() {
    this.setSrc();
  },
};
</script>
