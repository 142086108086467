<template>
  <div>
    <header class="full-width d-flex justify-center pt-8">
      <LogoImage />
    </header>
    <main v-html="consentForm" style="max-width: 1000px; margin: auto" class="py-16 px-4" />
  </div>
</template>
<script>
import consentForm from '../constants/legal';
import LogoImage from '../components/LogoImage.vue';

export default {
  data() {
    return {
      consentForm,
    };
  },
  components: {
    LogoImage,
  },
};
</script>
